import React from 'react';

const Explore = () => {
    return (
        <div>
            <h1>Explore page</h1>
        </div>
    );
};

export default Explore;