import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";
import HomePage from "../Pages/HomePage/HomePage";
import Features from "../Pages/Features/Features";
import Courses from "../Pages/Courses/Courses";
import Explore from "../Pages/Explore/Explore";
import Interview from "../Pages/Interviews/Interview";

const router = createBrowserRouter([
    {
        path:'/',
        element: <Main></Main>,
        children:[
            {
                path:'/',
                element: <HomePage></HomePage>,
            },
            {
                path:'/features',
                element:<Features></Features>
            },
            {
                path:'/courses',
                element:<Courses></Courses>
            },
            {
                path:'/explore',
                element:<Explore></Explore>
            },
            {
                path:'/interviews',
                element: <Interview></Interview>
            }
        ]
    }
])
export default router;