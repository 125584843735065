const primaryColor = "#060F15";
const secondaryColor = "#FFA544";
const blackColor = "#000000";
const whiteColor = "#FFFFFF";
const greyText = "#9B9EA1";

export default {
  primaryColor,
  secondaryColor,
  blackColor,
  whiteColor,
  greyText,
};
