import React from "react";
import AppColors from "./AppColors";
import { Link } from "react-router-dom";
import logo from "../../assets/Images/round-logo.png";

const Navbar = () => {
  return (
    <div className={`bg-[${AppColors.primaryColor}]`}>
      <div className="navbar">
        <div className="navbar-start">
          <div className="dropdown">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost text-white lg:hidden"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content bg-[#060F15] rounded-box z-[1] mt-3 w-52 p-2 shadow text-white"
            >
              {/* <li>
                <Link to="/features">Features</Link>
              </li>
              <li>
                <Link to="/interviews">Interviews</Link>
              </li>
              <li>
                <Link to="/explore">Explore</Link>
              </li> */}
              <li>
                <a href="https://course.moneyuniversity.pro/">Courses</a>
              </li>
            </ul>
          </div>
          <Link
            to="/"
            className="btn btn-ghost text-xl text-white font-semibold"
          >
            Money University
            <img src={logo} className="h-10 w-10" alt="" />
          </Link>
        </div>
        <div className="navbar-center hidden lg:flex text-white">
          <ul className="menu menu-horizontal px-1">
            {/* <li>
              <Link to="/features">Features</Link>
            </li>
            <li>
              <Link to="/interviews">Interviews</Link>
            </li>
            <li>
              <Link to="/explore">Explore</Link>
            </li> */}
            <li>
            <a href="https://course.moneyuniversity.pro/"> Courses </a>
            </li>
          </ul>
        </div>
        <div className="navbar-end hidden lg:flex">
          <a
            href="https://course.moneyuniversity.pro/lp-profile/"
            className={`me-5 btn btn-outline text-[#FFA544] hover:bg-[#FFA544] hover:text-black border-[1px] border-[#FFA544]`}
          >
            Login
          </a>
          <a
            href="https://course.moneyuniversity.pro/lp-profile/"
            className={`btn text-black bg-[#FFA544] border-none hover:bg-[#FFA555]`}
          >
            Join now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
