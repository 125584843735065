import React from "react";
import { FaPlus } from "react-icons/fa";
import TextSection from "./TextSection";
import uni from "../../assets/Images/vip.png"
import logo from "../../assets/Images/round-logo.png"

const ChangeUniversity = () => {
  return (
    <div>
      <div className="flex flex-row justify-center items-center mt-2 lg:mt-10">
        <div className="flex flex-col items-center text-sm lg:text-xl text-[#9B9EA1]">
          <img className="h-20 lg:h-48 rounded-full mb-5" src={uni} alt="" />
          <h1>VIP Group</h1>
        </div>
        <FaPlus className="h-5 w-5 lg:h-20 lg:w-20 mx-5 lg:mx-10 text-[#FFA544]" />
        <div className="flex flex-col items-center text-white text-sm lg:text-xl">
          <img className="h-20 lg:h-48 mb-5" src={logo} alt="" />
          <h1>Money University</h1>
        </div>
      </div>
      <TextSection></TextSection>
    </div>
  );
};

export default ChangeUniversity;
