import React from "react";
import { TbWorld } from "react-icons/tb";

const MyCard = ({ title, youtube }) => {
  return (
    <div>
      <div className="bg-[#141B23] w-full lg:w-full md:w-full pt-5  rounded-none lg:rounded-3xl flex flex-col items-center mx-2">
        <div className="flex flex-row justify-center items-center mb-5">
          <TbWorld className="h-[30px] w-[30px] lg:h-[50px] lg:w-[50px] me-3 text-[#FECF23]" />
          <h1 className="uppercase text-xl lg:text-3xl font-bold">{title}</h1>
        </div>
        <div className="mx-5">
          <p className="text-white text-sm lg:text-xl">
            Sell profitable products online.{" "}
            <span className="text-[#9B9EA1]">
              Industry expert will train you to
            </span>
          </p>
          <p className="text-[#9B9EA1] text-sm lg:text-xl">
            find winning products, create a perfect sotre and generate
          </p>
          <p className="text-sm lg:text-xl mb-10">trafic on your side.</p>
        </div>
        <a href="https://course.moneyuniversity.pro/"><button className="mb-10 btn btn-outline uppercase text-[#FFA544] hover:bg-[#FFA544] hover:text-black">
          Learn more
        </button></a>
        <iframe
          className="h-[300px] lg:h-[350px] w-full"
          src={youtube}
          width="100%"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default MyCard;
