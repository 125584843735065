import React from "react";

const TextSection = () => {
  return (
    <div className="">
      <div className="mx-5 lg:mx-0 mb-10">
        <p className="flex justify-center  text-[#9B9EA1] text-sm lg:text-xl mt-10">
          I N T R O D U C I N G{" "}
        </p>
        <p className="flex justify-center  text-white text-md lg:text-5xl mt-4">
          A MASSIVE UPGRADE
        </p>
        <p className="flex justify-center text-[#9B9EA1] text-sm lg:text-xl mt-2 lg:mt-5">
          The modern eudcation system is designed to make you a poor
        </p>
        <p className="flex flex-col lg:flex-row justify-center items-center text-[#9B9EA1] text-sm lg:text-xl mt-2 lg:mt-5">
          <span className="text-white me-1">
            Imagine you could get access to multi-millionier mentors
          </span>
          who will give you a
        </p>
        <p className="flex justify-center text-[#9B9EA1] text-sm lg:text-xl">
          step by step guide on how to make money
        </p>
        <p className="flex flex-col lg:flex-row items-center justify-center text-[#9B9EA1] text-sm lg:text-xl mt-2 lg:mt-5">
          That's <span className="mx-1 text-white">exactly</span> what you can
          find
          <span className="mx-1 text-white font-bold">
            inside Money University
          </span>
        </p>
      </div>
    </div>
  );
};

export default TextSection;
