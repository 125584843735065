import React from "react";
import { FaLock } from "react-icons/fa6";
import { MdDone, MdOutlineSecurity } from "react-icons/md";
import group from "../../assets/Images/groupall.webp";

const TakeAction = () => {
  return (
    <div className="flex justify-center my-10">
      <div className="w-[400px] h-[700px] bg-[#141B23] rounded-2xl">
        <p className="flex justify-center text-4xl font-extrabold text-white uppercase pt-10 tracking-wide">
          take action
        </p>
        <p className="text-2xl font-extrabold text-white flex justify-center my-2">
          You need to act now
        </p>
        <p className="flex flex-row justify-center my-5">
          <span className="mx-2 text-3xl font-extrabold text-[#FECF23]">
            $50.0
          </span>
        </p>
        <div className="mx-10 text-[#9B9EA1]">
          <div className="flex flex-row justify-start text-xl items-center mb-2">
            <MdDone className="text-[#FECF23]" />
            <h1 className="ms-2 text-2xl">Simple step-by-step tutorial</h1>
          </div>
          <div className="flex flex-row justify-start text-xl items-center mb-2">
            <MdDone className="text-[#FECF23]" />
            <h1 className="ms-2 text-2xl">9 wealth creation method</h1>
          </div>
          <div className="flex flex-row justify-start text-xl items-center mb-2">
            <MdDone className="text-[#FECF23]" />
            <h1 className="ms-2 text-2xl">Access to millioniers mentors</h1>
          </div>
          <div className="flex flex-row justify-start text-xl items-center mb-2">
            <MdDone className="text-[#FECF23]" />
            <h1 className="ms-2 text-2xl">Community chat group</h1>
          </div>
          <div className="flex flex-row justify-start text-xl items-center mb-2">
            <MdDone className="text-[#FECF23]" />
            <h1 className="ms-2 text-2xl">No experience</h1>
          </div>
          <div className="flex flex-row justify-start text-xl items-center mb-2">
            <MdDone className="text-[#FECF23]" />
            <h1 className="ms-2 text-2xl">Custom made learning app</h1>
          </div>
          <div className="flex flex-row justify-start text-xl items-center mb-2">
            <MdOutlineSecurity className="text-[#FECF23]" />
            <h1 className="ms-2 text-2xl">Cancel anytime, risk free</h1>
          </div>
          <div className="flex flex-row justify-start text-xl items-center mb-2">
            <FaLock className="text-[#FECF23]" />
            <h1 className="ms-2 text-2xl">$50.0 forever</h1>
          </div>
          <div>
            <div>
              <div className="flex justify-center mb-2 mt-2 lg:mt-5">
                <a href="https://course.moneyuniversity.pro/courses/basic-trading/">
                  <button
                    className="btn btn-md lg:btn-wide bg-[#FFA544] text-black font-extrabold border-none
         hover:bg-[#FFA544] mb-5 uppercase"
                  >
                    JOIN Money University
                  </button>
                </a>
              </div>
              <div className="flex flex-row justify-center items-center">
                <img className="me-2" src={group} alt="" />
                <span className="text-[#9B9EA1]">
                  Join 500++ like-minded students
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TakeAction;
