import React from "react";
import YoutubeVideo from "../../Components/HomePage/YoutubeVideo";
import JoinTheWorldButton from "../../Components/HomePage/JoinTheWorldButton";
import bg from "../../assets/Images/bg.jpg";
import MyCard from "../../Components/HomePage/MyCard";
import { FaHandHoldingDollar } from "react-icons/fa6";
import rev1 from "../../assets/review/rev1.jpg";
import rev2 from "../../assets/review/rev2.jpg";
import rev3 from "../../assets/review/rev3.jpg";
import rev4 from "../../assets/review/rev4.jpg";
import rev5 from "../../assets/review/rev5.jpg";
import rev6 from "../../assets/review/rev6.jpg";
import ChangeUniversity from "../../Components/HomePage/ChangeUniversity";
import TakeAction from "../../Components/HomePage/TakeAction";
import alert from "../../assets/Icons/alerts.webp";
import logo from "../../assets/Images/round-logo.png";


const HomePage = () => {
  const reviewimage = [rev1, rev2, rev3, rev4, rev5, rev6];
  return (
    <div className="min-h-screen">
      {/* This is title section */}
      <h1 className="text-white text-2xl lg:text-5xl text-center mt-0 lg:mt-20">
        MONEY MAKING IS <span className=" font-extrabold">A SKIL</span>
      </h1>
      <h1 className="text-white text-xl lg:text-4xl text-center mt-0 lg:mt-2 mb-5 lg:mb-10">
        We will teach you how to master it
      </h1>

      {/* Youtube Video section start */}
      <div className=" flex justify-center mb-5">
        <YoutubeVideo></YoutubeVideo>
      </div>
      {/* Youtube Video section end */}

      <JoinTheWorldButton></JoinTheWorldButton>

      <ChangeUniversity></ChangeUniversity>

      {/* <div className="w-full bg-[#09111C] mt-2 lg:mt-10 pb-2 lg:pb-20">
        <LifeLesson></LifeLesson>
        <JoinNetwork></JoinNetwork>
        <Multimillionier></Multimillionier>
        <JoinTheWorldButton></JoinTheWorldButton>
      </div> */}

      <div
        className="py-10"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* <TextSection></TextSection> */}
        {/* main section */}
        {/* <div className="w-full bg-no-repeat text-white flex flex-col lg:flex-row justify-center"> */}
          {/* Lefi side */}
          {/* <MyCard
            title={"e-commerce"}
            youtube="https://www.youtube.com/embed/qF1FMTT17tQ"
          ></MyCard> */}
          {/* Right side */}
          {/* <MyCard
            title={"copyright"}
            youtube="https://www.youtube.com/embed/SzAuB2FG79A"
          ></MyCard> */}
        {/* </div> */}
        {/* <div className=" mt-5 w-full bg-no-repeat text-white flex flex-col lg:flex-row justify-center">
          
          <MyCard
            title={"stock"}
            youtube="https://www.youtube.com/embed/p7HKvqRI_Bo"
          ></MyCard>
          
          <MyCard
            title={"business and finance"}
            youtube="https://www.youtube.com/embed/EJHPltmAULA"
          ></MyCard>
        </div> */}
        <div className="mt-5 w-full bg-no-repeat text-white flex flex-col lg:flex-row justify-center">
          
          {/* <MyCard
            title={"crypto investing"}
            youtube="https://www.youtube.com/embed/eTOK6VCCg4Q"
          ></MyCard> */}
          
          <MyCard
            title={"crypto trading"}
            youtube="https://www.youtube.com/embed/umtVtf5o0-o"
          ></MyCard>
        </div>
        {/* <div className=" mt-5 w-full bg-no-repeat text-white flex flex-col lg:flex-row justify-center">
          
          <MyCard
            title={"content creation & ai"}
            youtube="https://www.youtube.com/embed/lqXWVzWkkyc"
          ></MyCard>
          
          <MyCard
            title={"social media"}
            youtube="https://www.youtube.com/embed/I2pwcAVonKI"
          ></MyCard>
        </div> */}
      </div>
      <div className="w-full flex flex-row items-center justify-center mt-10">
        <FaHandHoldingDollar className=" h-8 w-8 lg:h-16 lg:w-16 text-[#FECF23] me-5" />
        <h1 className=" text-xl lg:text-3xl text-white uppercase font-bold">
          new skil
        </h1>
      </div>
      <div className="mx-5 lg:mx-0">
        <p className="flex justify-center text-[#9B9EA1] text-xl mt-4 lg:mt-10">
          We are constantly adding new skils and wealth creation methods that we
          could not
        </p>
        <p className="flex justify-center text-[#9B9EA1] text-xl mt-1 mb-10">
          previously disclossed. Stay tuned for more updates.
        </p>
        <JoinTheWorldButton></JoinTheWorldButton>
        <p className="flex justify-center text-[#9B9EA1] mt-10 text-md lg:text-2xl uppercase tracking-widest">
          Money University wines
        </p>
      </div>
      <p className="flex justify-center text-white mt-2 text-xl lg:text-5xl uppercase tracking-widest">
        our students are winning
      </p>
      <div className="my-10 mx-10 grid grid-cols-4">
        {reviewimage.map((item, index) => {
          return (
            <img
              key={index}
              className="h-20 w-20 lg:h-[500px] lg:w-[280px] rounded gap-10 mb-10"
              src={item}
              alt="review"
            />
          );
        })}
      </div>
      {/* <JoinTheWorldButton></JoinTheWorldButton> */}
      {/* <p className="flex justify-center text-[#9B9EA1] mt-10 text-xl lg:text-2xl uppercase tracking-widest">
        our students are leveling up
      </p>
      <p className="flex justify-center items-center text-white mt-2 text-3xl lg:text-5xl uppercase tracking-widest">
        student interviews
      </p> */}

      {/* <div className="mb-20 mx-0 lg:mx-20">
        <InterviewCard
          title="business mastery"
          subtitle="NOX 35."
          revinew="2000"
          youtube="https://www.youtube.com/embed/3_ljUlsurPU"
        ></InterviewCard>
        <InterviewCard
          title="content creation"
          subtitle="Mena, 34"
          revinew="3000"
          youtube="https://www.youtube.com/embed/fY7B5ZermSw"
        ></InterviewCard>
      </div> */}
      {/* <JoinTheWorldButton></JoinTheWorldButton> */}

      {/* You will get access to */}

      <div className="bg-[#06111A] w-full mt-5 lg:mt-20">
        {/* <div className="flex flex-row items-center justify-center pt-5 lg:pt-20">
          <FaUnlockAlt className="h-8 w-8 lg:h-16 lg:w-16 text-white me-5" />
          <p className="text-2xl lg:text-5xl text-white uppercase">
            you will get <span className="font-extrabold">access to</span>
          </p>
        </div> */}
        {/* <StepbyStepLearn video={video1}></StepbyStepLearn> */}
        {/* <DailyLiveSesson video={video2}></DailyLiveSesson> */}
        {/* <ExclusiveCommunity video={video3}></ExclusiveCommunity> */}
        {/* <div className="pb-10"> */}
          {/* <JoinTheWorldButton></JoinTheWorldButton> */}
        {/* </div> */}
        {/* <AchiveTheGoal></AchiveTheGoal> */}
        {/* You have two choise */}

        {/* <p className="uppercase font-bold text-2xl lg:text-5xl text-white flex justify-center my-2 lg:my-20">
          the choise is your
        </p>
        <div>
          <iframe
            className="h-[300px] lg:h-[450px] w-full lg:w-3/5 mx-auto"
            src="https://www.youtube.com/embed/DmopIZ9x3Eg"
            width="80%"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div> */}
        {/* You have two choise end*/}
        {/* Take action */}
        <TakeAction></TakeAction>
        <p className="flex justify-center text-white uppercase text-2xl lg:text-4xl mb-2 tracking-wide">
          or do nothing..
        </p>
        <p className="flex justify-center text-white uppercase text-xl lg:text-2xl mb-10 tracking-wide">
          Go watch Netflix, Work a 9-5
        </p>
        <div className="flex justify-center mb-10">
          <img src={alert} alt="" className=" shadow-2xl" />
        </div>
        <p className="flex justify-center text-white uppercase text-2xl lg:text-4xl mb-2 tracking-wide font-bold">
          Link-in yours price of $50.00
        </p>
        <p className="text-[#9B9EA1] mt-10 mb-14 text-2xl flex justify-center">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, ullam?
        </p>
        <p className="text-[#9B9EA1] text-2xl flex justify-center">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, ullam?
        </p>
        <p className="text-[#9B9EA1] mb-2 text-2xl flex justify-center">
          Lorem ipsum dolor sit.
        </p>
        <p className="text-[#9B9EA1] mt-5 text-2xl flex justify-center">
          Lorem ipsum dolor sit amet consectetur adipisicing.
        </p>
        <p className="text-[#9B9EA1] mb-14 text-2xl flex justify-center">
          Lorem ipsum dolor sit amet.
        </p>
        {/* <JoinTheWorldButton></JoinTheWorldButton> */}

        {/* <div className="bg[#060F15]">
          <p className="text-xl lg:text-4xl text-white my-10 uppercase flex justify-center">
            frequently ask <span className="font-bold ms-2">question</span>
          </p>
          <FAQ></FAQ>
        </div> */}
        <JoinTheWorldButton></JoinTheWorldButton>
        <div className="flex justify-center my-20">
          <img src={logo} alt="" className="h-[200px] w-[200px]" />
        </div>
        <p className="flex mx-10 justify-center text-xl text-white underline cursor-pointer mb-2">
          Tearms & Conditions
        </p>
        <p className="flex justify-center text-xl text-white underline cursor-pointer mb-2">
          Privacy Policy
        </p>
        <p className="flex justify-center text-md text-white cursor-pointer mb-5">
          support:<a href="http://t.me/Stoneshooter_owner"className=" ms-2"> @Stoneshooter_owner</a>
        </p>
        <p className="flex justify-center text-xl text-[#777777] cursor-pointer">
          Everything taught within Money University for educational purposes
          only. It is up to
        </p>
        <p className="flex justify-center text-xl text-[#777777] cursor-pointer mb-4">
          each student to implement and do the work
        </p>
        <p className="flex justify-center text-xl text-[#777777] cursor-pointer pb-10">
          Money university doesn't make any guarantees or promises regarding
          your results
        </p>
      </div>
    </div>
  );
};
export default HomePage;
