import React from "react";
import group from "../../assets/Images/groupall.webp";

const JoinTheWorldButton = () => {
  return (
    <div>
      <div className="flex justify-center mb-2 mt-2 lg:mt-5">
       <a href="https://course.moneyuniversity.pro/"> <button
          className="btn btn-md lg:btn-wide bg-[#FFA544] text-black font-extrabold border-none
         hover:bg-[#FFA544] mb-5 uppercase"
        >
          JOIN Money University
        </button></a>
      </div>
      <div className="flex flex-row justify-center items-center">
        <img className="me-2" src={group} alt="" />
        <span className="text-[#9B9EA1]">Join 500++ like-minded students</span>
      </div>
    </div>
  );
};

export default JoinTheWorldButton;
